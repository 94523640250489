<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                    <li class="active a">数据概况</li>
                    <li class=" a"><router-link to="/index6/cwmx66">财务明细</router-link></li>
                        <li class=" a"><router-link to="/index6/fc66">分成设置</router-link></li>
                      <li class=" a"><router-link to="/index6/fp66">发票管理</router-link></li>
                </div>
                 <div  class="item">  
             <li class="a" ><router-link to="/index6/xiaoxi66" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>           <li style="cursor:pointer;" @click="dialogFormVisible = true">财务总监<i class="el-icon-caret-bottom"></i>  </li>
                     </div>    

                                        <el-dialog title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                            <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                          <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
         <div class="search"></div>
            <div class="content">
                  <ul class="content_ul">
                     <li style="background:#9351CC;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">平台总收益</p>
                          <p style="font-size:30px;margin: 0">{{infos.total_benefit/100}}</p>
                     </li>
                     <li style="background:#00ADD6;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">上月收入</p>
                        <p style="font-size:30px;margin: 0">{{infos.last_month_benefit/100}}</p>
                     </li>
                     <li style="background:#F5C900;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">本月收入</p>
                          <p style="font-size:30px;margin: 0">{{infos.this_month_benefit/100}}</p>
                     </li>
                      <li style="background:#49ADA8;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">今日平台收益</p>
                        <p style="font-size:30px;margin: 0">{{infos.today_benefit/100}}</p>
                     </li>
                     <li style="background:#80B547;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                     <p style="font-size:14px;margin:12px 0">今日成交额</p>
           <p style="font-size:30px;margin: 0">{{infos.today_fee/100}}</p>
                     </li>
                     <li style="background:#0093E0;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">今日订单数</p>
         <p style="font-size:30px;margin: 0">{{infos.order_count}}</p>
                     </li>
                     
                 </ul>

       
                     <div id="myChart1"  :style="{ height: '240px'}"></div>

                      <div id="myChart2"  :style="{ height: '240px'}"></div>
            </div>
  </div>
</template>

<script>
export default {
 data(){
        return{
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
infos:[],
            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       
methods:{
      tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a11(){
          this.$axios({                                 
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:this.info,
                        }) .then(res=>{
                            this.dialogFormVisible = false
                              this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
                        })
        .catch(function(){
           
        })  
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
},

        mounted(){
               this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })    
  this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_ptbenefit_statistic",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                     
                        }) .then(res=>{
                          this.infos = res.data.result
                          console.log(this.info)
                        })
    
    this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_ptcje",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                     
                        }) .then(res=>{
                             
            let arrs = []
            let arrs1 = []
 
            res.data.result.forEach(e => {
            arrs.push(e.month)
             arrs1.push(e.total)
     
         })
   
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart1'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: '平台成交额',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['成交交易'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '成交易额',
                            type: 'bar',
                            
                            data:arrs1
                        },
                       
                      
                    ]
                };

                myChartDrawer.setOption(option);
                            
                        })







  this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_ptbenefit",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                     
                        }) .then(res=>{
                             
            let arrs = []
            let arrs1 = []
 
            res.data.result.forEach(e => {
            arrs.push(e.month)
             arrs1.push(e.total)
     
         })
     
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart2'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: '平台收益统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD'],
                    legend: {
                        data: ['成交交易'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '成交易额',
                            type: 'bar',
                            
                            data:arrs1
                        },
                       
                      
                    ]
                };

                myChartDrawer.setOption(option);
                            
                        })








   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :10  }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :9  }
        })

     .then(res=>{
     
     this.tableData=data1.concat(this.data2)

        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })

         
  

               this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>

/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/ .el-dialog{
        width: 25%;

    float: right;
  
}
    .content{
        background: #f5f5f5;
        padding:10px 40px;
    }
 .content .content_ul{
            display: flex;
            justify-content: space-between;margin:30px 0
        }
         .content .content_ul li{
             width: 12%;padding-left: 15px;
         }




.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>